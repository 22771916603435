import { useIsEditorInitialised as CE_useIsEditorInitialised } from "ce/IDE/hooks/useIsEditorInitialised";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import { useLocation } from "react-router";
import { IDE_TYPE } from "ee/entities/IDE/constants";
import { useSelector } from "react-redux";
import { getIsPackageEditorInitialized } from "ee/selectors/packageSelectors";
import { getIsWorkflowEditorInitialized } from "ee/selectors/workflowSelectors";

function useIsEditorInitialised() {
  const { pathname } = useLocation();
  const IDEType = getIDETypeByUrl(pathname);

  const isPackageEditorInitialised = useSelector(getIsPackageEditorInitialized);
  const isWorkflowEditorInitialised = useSelector(
    getIsWorkflowEditorInitialized,
  );
  const isAppEditorInitialised = CE_useIsEditorInitialised();

  switch (IDEType) {
    case IDE_TYPE.None:
    case IDE_TYPE.App:
      return isAppEditorInitialised;
    case IDE_TYPE.Package:
      return isPackageEditorInitialised;
    case IDE_TYPE.Workflow:
      return isWorkflowEditorInitialised;
  }
}

export { useIsEditorInitialised };
