import React from "react";
import { Button, Flex } from "@appsmith/wds";

import styles from "./styles.module.css";
import type { Message } from "./types";

interface PromptSuggestionsProps {
  suggestions: Required<Message>["promptSuggestions"];
  onApplyAssistantSuggestion?: (suggestion: string) => void;
}

export const ChatPromptSuggestions = (props: PromptSuggestionsProps) => {
  const { onApplyAssistantSuggestion, suggestions } = props;

  if (suggestions.length === 0) return null;

  return (
    <Flex className={styles.suggestions} gap="spacing-2" wrap="wrap">
      {suggestions.filter(Boolean).map((suggestion) => (
        <Button
          color="neutral"
          key={suggestion.label}
          onPress={() => onApplyAssistantSuggestion?.(suggestion.value)}
          variant="subtle"
        >
          {suggestion.label}
        </Button>
      ))}
    </Flex>
  );
};
