import { useLayoutEffect, useState } from "react";
import { CHAT_SIZE } from "../component/types";
import { getFitPageChatHeight } from "../helpers";

export const useAIChatHeight = (
  size: keyof typeof CHAT_SIZE = CHAT_SIZE.FIT_PAGE,
  isEmbed: boolean,
) => {
  const [chatHeight, setChatHeight] = useState("");

  useLayoutEffect(() => {
    const canvasElem = document.querySelector(".canvas");

    if (canvasElem) {
      switch (size) {
        case CHAT_SIZE.MEDIUM:
          return setChatHeight("600px");
        case CHAT_SIZE.LARGE:
          return setChatHeight("800px");
        case CHAT_SIZE.FIT_PAGE:
          return setChatHeight(
            getFitPageChatHeight(canvasElem.clientHeight, isEmbed),
          );
        default:
          return setChatHeight(
            getFitPageChatHeight(canvasElem.clientHeight, isEmbed),
          );
      }
    }
  }, [size, isEmbed]);

  return chatHeight;
};
