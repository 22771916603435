import { CHAT_SIZE } from "ee/modules/ui-builder/ui/wds/WDSAIChatWidget/component";
import { ValidationTypes } from "constants/WidgetValidation";
import { objectKeys } from "@appsmith/utils";
import { startCase, capitalize } from "lodash";

export const propertyPaneStyle = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "chatHeightSize",
        label: "Height",
        controlType: "DROP_DOWN",
        fullWidth: true,
        helpText: "Sets the height of the chat widget",
        options: objectKeys(CHAT_SIZE).map((size) => ({
          label: capitalize(
            startCase(CHAT_SIZE[size as keyof typeof CHAT_SIZE]),
          ),
          value: CHAT_SIZE[size as keyof typeof CHAT_SIZE],
        })),
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        isReusable: true,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: Object.values(CHAT_SIZE),
            default: CHAT_SIZE.MEDIUM,
          },
        },
      },
    ],
  },
];
