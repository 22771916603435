import { Spinner, ToolbarButtons } from "@appsmith/wds";
import clamp from "lodash/clamp";
import round from "lodash/round";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import type {
  Citation,
  PdfCitationHighlightAnchorCoordinate,
} from "../../types";
import styles from "./styles.module.css";
import type { PdfDocumentViewerProps } from "./types";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

const MIN_ZOOM_SCALE = 1;
const MAX_ZOOM_SCALE = 3;
const ZOOM_SCALE_STEP = 0.25;

export const PdfDocumentViewer = ({
  citation,
  isResizing,
  ...rest
}: PdfDocumentViewerProps) => {
  const citationRef = useRef<Citation | null>(null);
  const documentNodeRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [allPagesRendered, setAllPagesRendered] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [pageScale, setPageScale] = useState<number | undefined>(undefined);
  const { end, start } = citation.coordinates;

  const findCitationHighlight = useCallback(
    (pageNumber: number): Element | null => {
      return (
        document
          .querySelector(`.react-pdf__Page[data-page-number='${pageNumber}']`)
          ?.querySelector(`.${styles.citationHighlight}`) || null
      );
    },
    [],
  );

  const scrollToCitation = useCallback((): void => {
    const node = findCitationHighlight(start.pageNumber);

    if (!node) return;

    node.scrollIntoView({ block: "start" });

    if (documentNodeRef.current && node instanceof HTMLElement) {
      documentNodeRef.current.scrollLeft = node.offsetLeft;
    }
  }, [start.pageNumber, findCitationHighlight]);

  const calcPageScale = useCallback(() => {
    const citationWidth = (containerWidth / 100) * ((end.x - start.x) * 100);

    return containerWidth / citationWidth;
  }, [containerWidth, end.x, start.x]);

  // https://github.com/wojtekmaj/react-pdf/issues/398#issuecomment-501237672
  const makeOnAllPagesRenderedCallback = (
    totalPagesCount: number,
    onAllPagesRendered: () => void,
  ) => {
    let pagesCount = 0;

    function onRenderSuccess() {
      pagesCount += 1;

      if (pagesCount === totalPagesCount) {
        onAllPagesRendered();
      }
    }

    return {
      onRenderSuccess,
    };
  };

  useEffect(
    function handleDocumentIsLoaded() {
      if (totalPageCount > 0) {
        setPageScale(calcPageScale());
      }
    },
    [totalPageCount],
  );

  useEffect(
    function handleAllPagesRendered() {
      if (allPagesRendered) {
        scrollToCitation();
      }
    },
    [allPagesRendered],
  );

  useEffect(
    function handleCitationChange() {
      if (!containerWidth) return;

      // First render
      if (!citationRef.current) {
        citationRef.current = citation;

        return;
      }

      if (citationRef.current.id !== citation.id) {
        // If new citation is for the same document we don't need to reset the state
        // We can set the scale and scroll to the citation right away
        if (citationRef.current.sourceUrl === citation.sourceUrl) {
          setPageScale(calcPageScale());
          scrollToCitation();
        }
        // If the document is different we need to reset the state
        // and start loading the new document from scratch
        else {
          setPageScale(undefined);
          setTotalPageCount(0);
          setAllPagesRendered(false);
          setLoadError(false);
        }

        citationRef.current = citation;
      }
    },
    [citation, containerWidth],
  );

  useEffect(
    function updateContainerWidthAfterResizing() {
      if (!documentNodeRef.current || isResizing) return;

      setContainerWidth(documentNodeRef.current.clientWidth || 0);
    },
    [isResizing],
  );

  const pageProps = useMemo(
    () =>
      makeOnAllPagesRenderedCallback(totalPageCount, () => {
        setAllPagesRendered(true);
      }),
    [totalPageCount],
  );

  const shouldShowCitationHighlight = (pageNumber: number): boolean => {
    return pageNumber >= start.pageNumber && pageNumber <= end.pageNumber;
  };

  const isSinglePageCitation = () => start.pageNumber === end.pageNumber;

  const resolveCitationHighlightStyle = ({
    end,
    pageNumber,
    start,
  }: {
    end: PdfCitationHighlightAnchorCoordinate;
    start: PdfCitationHighlightAnchorCoordinate;
    pageNumber: number;
  }) => {
    const left = start.x;
    const width = end.x - start.x;
    let top = 0;
    let height = 0;

    if (isSinglePageCitation()) {
      top = start.y;
      height = end.y - start.y;
    } else {
      // Stretch the highlight to the bottom of the first page
      if (pageNumber === start.pageNumber) {
        top = start.y;
        height = 1 - start.y;
      }
      // Cover intermediate pages entirely
      else if (pageNumber < end.pageNumber) {
        top = 0;
        height = 1;
      }
      // Stretch the highlight to the top of the last page
      else {
        top = 0;
        height = end.y;
      }
    }

    return {
      left: `${left * 100}%`,
      top: `${top * 100}%`,
      width: `${width * 100}%`,
      height: `${height * 100}%`,
    };
  };

  const normalizePageScale = (scale: number): number => {
    return round(clamp(scale, MIN_ZOOM_SCALE, MAX_ZOOM_SCALE), 1);
  };

  const handleZoomInButtonClick = () => {
    setPageScale((prevPageScale = 1) =>
      normalizePageScale(prevPageScale + ZOOM_SCALE_STEP),
    );
  };

  const handleZoomOutButtonClick = () => {
    setPageScale((prevPageScale = 1) =>
      normalizePageScale(prevPageScale - ZOOM_SCALE_STEP),
    );
  };

  return (
    <div className={styles.pdfDocumentViewer} {...rest}>
      <div className={styles.content}>
        {!allPagesRendered && !loadError && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}

        {loadError && <div>{"Can't load the file..."}</div>}

        <div
          className={styles.document}
          ref={documentNodeRef}
          style={{
            ...((isResizing || !allPagesRendered || !pageScale) &&
              ({ opacity: "0" } as React.CSSProperties)),
          }}
        >
          <Document
            file={citation.sourceUrl}
            loading=""
            onLoadError={() => setLoadError(true)}
            onLoadSuccess={({ numPages }) => setTotalPageCount(numPages)}
            options={options}
          >
            {Array.from(new Array(totalPageCount), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                loading=""
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={pageScale}
                width={containerWidth}
                {...pageProps}
              >
                {shouldShowCitationHighlight(index + 1) && (
                  <div
                    className={styles.citationHighlight}
                    style={resolveCitationHighlightStyle({
                      end,
                      start,
                      pageNumber: index + 1,
                    })}
                  />
                )}
              </Page>
            ))}
          </Document>
        </div>
      </div>

      <div className={styles.controlPanel}>
        <div className={styles.zoomButtons}>
          <ToolbarButtons
            density="compact"
            items={[
              {
                id: "zoom-in",
                icon: "zoom-in",
              },
              {
                id: "zoom-out",
                icon: "zoom-out",
              },
            ]}
            onAction={(id) => {
              if (id === "zoom-in") handleZoomInButtonClick();

              if (id === "zoom-out") handleZoomOutButtonClick();
            }}
            size="small"
            variant="subtle"
          />
        </div>
      </div>
    </div>
  );
};
