import { useEffect, useState } from "react";

export const useTextFileLoader = (url: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [content, setContent] = useState<string | null>(null);

  useEffect(
    function handleUrlChange() {
      setIsLoading(true);
      setHasError(false);
      setContent(null);

      const fetchDocument = async () => {
        try {
          const res = await fetch(url);
          const content = await res.text();

          setContent(content);
        } catch (_error) {
          setHasError(true);
        } finally {
          setIsLoading(false);
        }
      };

      fetchDocument();
    },
    [url],
  );

  return { isLoading, hasError, content };
};
