import React, { useCallback } from "react";
import { ChatCitationButton } from "../ChatCitationButton";
import type { NotionCitation } from "../types";

interface ChatNotionPageCitationItemProps {
  citation: NotionCitation;
}

export const ChatNotionPageCitationItem = React.memo(
  ({ citation }: ChatNotionPageCitationItemProps) => {
    const handleButtonPress = useCallback(() => {
      window.open(citation.location, "_blank", "noopener,noreferrer");
    }, [citation.location]);

    return (
      <ChatCitationButton
        citationName={citation.name}
        onPress={handleButtonPress}
      />
    );
  },
);
