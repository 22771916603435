import identity from "lodash/identity";
import type { Message, MessageDto } from "../../component";
import * as CitationMapper from "./citationMapper";

export const fromDto = (messages: MessageDto[]): Message[] => {
  return messages.map((message: MessageDto) => {
    return {
      createdAt: message.createdAt,
      id: message.id,
      content: message.message[0].text.value,
      role: message.role,
      citations: message.citations
        ?.map(CitationMapper.fromDto)
        .filter(identity),
    };
  });
};
