import React, { forwardRef } from "react";
import { clsx } from "clsx";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { getTypographyClassName } from "@appsmith/wds-theming";

import styles from "./styles.module.css";
import { components } from "./components";
import type { MarkdownProps } from "./types";

export const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(
  (props, ref) => {
    const { children, className, extraComponents, options, ...rest } = props;

    return (
      <div
        className={clsx(
          styles.markdown,
          getTypographyClassName("body"),
          className,
        )}
        {...rest}
        ref={ref}
      >
        <ReactMarkdown
          components={{
            ...components,
            ...extraComponents,
          }}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          {...options}
        >
          {children}
        </ReactMarkdown>
      </div>
    );
  },
);

Markdown.displayName = "Markdown";
