import * as React from "react";

export const EmptyCitationImage = () => (
  <svg
    fill="none"
    height="201"
    viewBox="0 0 250 201"
    width="250"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 .9h250v200H0z" fill="#fff" />
    <path
      clipRule="evenodd"
      d="M207 65.9a7 7 0 1 1 0 14h-40a7 7 0 1 1 0 14h22a7 7 0 0 1 0 14h-10.174c-4.874 0-8.826 3.134-8.826 7q0 3.866 6 7a7 7 0 1 1 0 14H93a7 7 0 1 1 0-14H54a7 7 0 1 1 0-14h40a7 7 0 1 0 0-14H69a7 7 0 1 1 0-14h40a7 7 0 1 1 0-14zm0 28a7 7 0 1 1 0 14 7 7 0 0 1 0-14"
      fill="#F4F5F6"
      fillRule="evenodd"
    />
    <path
      d="M154.91 64.73a1.25 1.25 0 1 0-2.477.34zm8.064 68.013 1.24-.153-.002-.017zm.835 6.806 1.241-.152zm-3.482 4.458.152 1.24zm-58.561 7.19.153 1.241zm-4.457-3.483 1.24-.152zm-9.016-73.427 1.24-.153zm1.762-2.231.14 1.242zm4.998.697a1.25 1.25 0 0 0-.279-2.485zm3.65-2.925a1.25 1.25 0 0 0 .278 2.484zm4.865 1.97a1.25 1.25 0 1 0-.278-2.485zm48.865-6.728 9.302 67.843 2.477-.34-9.302-67.843zm9.3 67.825.836 6.806 2.481-.304-.836-6.807zm.836 6.806a2.75 2.75 0 0 1-2.395 3.065l.305 2.481a5.25 5.25 0 0 0 4.571-5.85zm-2.395 3.065-58.56 7.19.305 2.482 58.56-7.191zm-58.56 7.19a2.75 2.75 0 0 1-3.065-2.394l-2.48.305a5.25 5.25 0 0 0 5.85 4.571zm-3.065-2.394-9.015-73.428-2.482.305 9.016 73.428zm-9.015-73.428a.75.75 0 0 1 .653-.835l-.305-2.482a3.25 3.25 0 0 0-2.83 3.622zm.653-.835.008-.001-.28-2.485-.033.004zm.008-.001 4.858-.545-.279-2.485-4.858.545zm8.786-.986 4.587-.514-.278-2.485-4.587.515z"
      fill="#81858B"
    />
    <path
      clipRule="evenodd"
      d="m151.14 69.169 8.42 61.484.757 6.168c.244 1.987-1.15 3.793-3.113 4.034l-52.442 6.44c-1.964.241-3.754-1.175-3.998-3.162l-8.15-66.376a2 2 0 0 1 1.742-2.23l6.487-.796"
      fill="#E7E8E8"
      fillRule="evenodd"
    />
    <path
      d="M107.922 54.9a2.75 2.75 0 0 1 2.75-2.75h45.557c.729 0 1.428.29 1.944.805l13.443 13.435a2.75 2.75 0 0 1 .806 1.945V130.9a2.75 2.75 0 0 1-2.75 2.75h-59a2.75 2.75 0 0 1-2.75-2.75z"
      fill="#fff"
      stroke="#81858B"
      strokeWidth="2.5"
    />
    <path
      d="M156.672 53.303V64.9a3 3 0 0 0 3 3h7.933M118 118.9h26m-26-51h26zm0 12h43zm0 13h43zm0 13h43z"
      stroke="#81858B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
  </svg>
);
