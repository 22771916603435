/**
 * in case of embeds, we want to set the height to the full canvas height
 * otherwise, we want to subtract the outer spacing from the canvas height
 * when the ai chat widget is marked for fit page
 */
export const getFitPageChatHeight = (
  canvasHeight: number,
  isEmbed: boolean,
) => {
  if (isEmbed) return `calc(${canvasHeight}px`;

  return `calc(${canvasHeight}px - (var(--outer-spacing-4) * 2)`;
};
