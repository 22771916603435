import { buildUrlTextFragment } from "@appsmith/utils";
import React, { useCallback, useMemo } from "react";
import { ChatCitationButton } from "../ChatCitationButton";
import type { WebScrapeCitation } from "../types";

interface ChatWebPageCitationItemProps {
  citation: WebScrapeCitation;
}

export const ChatWebPageCitationItem = React.memo(
  ({ citation }: ChatWebPageCitationItemProps) => {
    const chunkFragments = useMemo((): string[] => {
      return (
        citation.chunk
          .split(/\n+/)
          .map((line) => line.trim())
          // Exclude short fragments that are likely to match wrong text
          .filter((line) => line.length > 16)
      );
    }, [citation.chunk]);

    const handleButtonPress = useCallback(() => {
      window.open(
        `${citation.location}#${buildUrlTextFragment(chunkFragments)}`,
        "_blank",
        "noopener,noreferrer",
      );
    }, [citation.location, chunkFragments]);

    return (
      <ChatCitationButton
        citationName={citation.name}
        onPress={handleButtonPress}
      />
    );
  },
);
