import React from "react";
import { ChatCitationButton } from "../ChatCitationButton";
import type { Citation } from "../types";

interface ChatDocumentCitationItemProps {
  citation: Citation;
  onOpen?: (citation: Citation) => void;
}

export const ChatDocumentCitationItem = ({
  citation,
  onOpen,
}: ChatDocumentCitationItemProps) => {
  return (
    <ChatCitationButton
      citationName={citation.name}
      onPress={() => onOpen?.(citation)}
    />
  );
};
